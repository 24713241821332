import ApiService from "@/core/services/ApiService";
import { Provider } from "@/modules/provider/provider.module";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/providers";

export async function searchProviders(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data;
      //TODO DEBT
      items.data.forEach((item: any) => {
        item.effectiveDate = new Date(item.effectiveDate);
        item.termDate = new Date(item.termDate);
      });
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getProviders() {
  return await ApiService.get(url)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getProvidersList() {
  const request = {
    keyword: "",
    pageNumber: 0,
    pageSize: 100,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        item.effectiveDate = new Date(item.effectiveDate);
        item.termDate = new Date(item.termDate);
      });
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addProvider(provider) {
  return await ApiService.post(url, provider as never).then();
}

export async function getProvider(providerId): Promise<Provider> {
  return await ApiService.get(url + "/" + providerId)
    .then((res) => {
      const provider = res.data;
      if (provider.effectiveDate)
        provider.effectiveDate = new Date(provider.effectiveDate);
      if (provider.termDate)
        provider.termDate = new Date(provider.termDate);

      return provider;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getDefaultProvider(): Promise<Provider> {
  return await ApiService.get(url + "/GetDefault")
    .then((res) => {
      const provider = res.data;
      if (provider.effectiveDate)
        provider.effectiveDate = new Date(provider.effectiveDate);
      if (provider.termDate)
        provider.termDate = new Date(provider.termDate);

      return provider;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateProvider(provider) {
  if (provider) {
    await ApiService.put(url + "/" + provider.id, provider as never)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function updateTaxonomy(providerId, taxonomy) {
  if (taxonomy) {
    let request = {};
    request = { Id: providerId, taxonomy: taxonomy };
    await ApiService.post(url + "/updateTaxonomy", request as never)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function updateStateLicenseNumberByProviderId(providerId, stateLicenseNumbers) {
  if (providerId) {
    await ApiService.post(url + "/updateStateLicenseNumber/" + providerId, {
      Id: providerId,
      StateLicenseNumbers: stateLicenseNumbers,
    } as never)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function getStateLicenseNumberByProviderId(providerId) {
  if(providerId){
    return await ApiService.get(url + "/getStateLicenseNumber", providerId as never)
      .then((res) => {
        const items = res.data;
        return items;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteProvider(provider) {
  if (provider) {
    return await ApiService.delete(url + "/" + provider?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}
